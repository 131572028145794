<template>
  <div>
    <DisplayContainer label="">
      <v-card
        v-if="!minDaysPassedSinceLastStateChange"
        elevation="0"
        class="mt-4"
        :loading="true"
      >
        <template slot="progress">
          <v-progress-linear
            :value="daysSinceLastStateChangeProgress"
            striped
          ></v-progress-linear>
        </template>
        <v-card-text>
          <v-alert
            type="info"
            text
            outlined
            dense
          >
            Das Projekt kann noch nicht auf <b :style="{color: nextStatus.color}">{{ nextStatus.description }}</b> gesetzt werden,
            da erst {{ daysSinceLastStateChangeRounded }} von 14 Tagen seit dem setzten auf
            <b :style="{color: expectedStatus.color}">{{ expectedStatus.description }}</b> vergangen sind.
          </v-alert>
          <v-checkbox
            v-model="acceptIgnoreWarning"
            color="warning"
            label="14 Tage Frist ignorieren, und Aufgabe bearbeiten!"
          ></v-checkbox>
        </v-card-text>
      </v-card>
      <v-form
        ref="form"
        v-model="formValid"
        :disabled="!isEditable"
      >
        <v-container fluid>
          <v-row align="center">
            <v-col
              class="d-flex"
              cols="12"
              sm="6"
            >
              <v-text-field
                :value="constructionPlanningTool"
                label="Verwendetes Bausteuerungstool (readonly)"
                :rules="[v => !!v || 'Dem Projekt ist kein Bausteuerungstool hinterlegt!']"
                required
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="6"
            >
              <DatePicker
                label="Baustart"
                :value="constructionStartDate"
                disableWeekend
                disableHolidays
                :errorMessage="dateInputError"
                @input="constructionStartDate = $event"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </DisplayContainer>
    <div class="mb-4 align-buttons-right">
      <v-btn
        :disabled="!isEditable || !formWithDateValid"
        :loading="isSubmitting"
        color="primary"
        @click="completeTaskDialog = true"
      >
        Tiefbau Freigabe anfragen
      </v-btn>
    </div>
    <v-dialog
      v-if="editable"
      v-model="completeTaskDialog"
      width="20vw"
    >
      <v-card>
        <v-card-text class="pt-6">
          <p>
            Mit der Freigabe der Aufgabe geht das Projekt <b>{{ project.projektId }} - {{ project.name }}</b> in den nächsten Projektstatus <b :style="{color: nextStatus.color }">{{ nextStatus.description }}</b>.<br /><br />
            Die Freigabe des Projekts zum Tiefbau hat irreversible Auswirkungen.
            Aus diesem Grund muss die Freigabe noch von einer weiteren Person aus dem <b>Rollout</b> bestätigt werden.
            Sobald das Projekt in die nächste Phase geht, wird:
          </p>
          <ul>
            <li>Eine Homes Passed Freigabe für neue Verträge benötigt</li>
            <li>Der Vertriebsstatus auf “Aktive Nachvermarktung” gesetzt</li>
          </ul>
          <v-alert
            v-if="!minDaysPassedSinceLastStateChange"
            class="mt-4"
            type="warning"
            text
            outlined
            dense
          >
            Die 14 Tage Frist wird hierdurch nicht eingehalten!
          </v-alert>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn
            :disabled="isSubmitting"
            small
            color="secondary"
            class="align-center"
            @click="completeTaskDialog = !completeTaskDialog"
          >
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!editable"
            :loading="isSubmitting"
            small
            color="warning"
            class="align-center"
            @click="submitTask"
          >
            Tiefbau Freigabe anfragen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DatePicker from '@/components/elements/DatePicker.vue';
import DisplayContainer from '../../components/elements/DisplayContainer.vue';

export default {
  name: 'ActionFreigabeProjektZumTiefbauBereit',
  components: {
    DatePicker,
    DisplayContainer
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    variables: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    isSubmitting: false,
    formValid: false,
    completeTaskDialog: false,
    constructionStartDate: null,
    acceptIgnoreWarning: false,
    nowInterval: undefined,
    now: Date.now()
  }),
  computed: {
    isCamundaAdmin() {
      return this.$auth.user().userRoles.includes('camunda-admin');
    },
    project() {
      return this.variables.$project;
    },
    constructionPlanningTool() {
      return this.project.constructionManagementTool;
    },
    step() {
      return this.variables.$currentStep;
    },
    expectedStatus() {
      return this.variables.$currentStep.expectedProjectStatus;
    },
    nextStatus() {
      return this.variables.$currentStep.nextProjectStatus;
    },
    daysSinceLastStateChange() {
      const lastChange =
        this.variables.projectStatusDateMap[
          this.variables.$currentStep.expectedProjectStatus.name
        ];
      return (this.now - new Date(lastChange)) / (60 * 60 * 24 * 1000);
    },
    daysSinceLastStateChangeRounded() {
      return Math.floor(this.daysSinceLastStateChange);
    },
    daysSinceLastStateChangeProgress() {
      return Math.min(100, (this.daysSinceLastStateChange / 14) * 100);
    },
    minDaysPassedSinceLastStateChange() {
      return this.daysSinceLastStateChange >= 14;
    },
    isEditable() {
      return (
        this.editable &&
        (this.minDaysPassedSinceLastStateChange || this.acceptIgnoreWarning)
      );
    },
    dateInputError() {
      if (!this.constructionStartDate) {
        return 'Es muss ein Baustart gesetzt werden!';
      }

      return null;
    },
    formWithDateValid() {
      return this.formValid && this.dateInputError === null;
    }
  },
  mounted() {
    this.$refs.form.validate();
    this.startNowTimer();
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
    this.stopNowTimer();
  },
  methods: {
    submitTask: function () {
      this.isSubmitting = true;
      this.$parent
        .submitTask({
          newProjectStateDto: this.nextStatus,
          constructionStartDate: this.constructionStartDate
        })
        .then(() => {
          this.completeTaskDialog = false;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    handleVisibilityChange(e) {
      console.log(e);
      if (document.hidden) {
        this.stopNowTimer();
      } else {
        this.startNowTimer();
      }
    },
    startNowTimer: function (interval = 1000) {
      if (!this.nowInterval) {
        this.nowInterval = setInterval(() => {
          this.now = Date.now();
        }, interval);
      }
    },
    stopNowTimer: function () {
      if (this.nowInterval) {
        clearInterval(this.nowInterval);
        this.nowInterval = null;
      }
    }
  }
};
</script>
